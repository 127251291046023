import { useEffect, useState } from "react";
import ServiceBox from "./serviceBox";
import apiUrl from "../api/config";

export default function ServiceSlider () {

    const [services , setServices] = useState([])

    useEffect(()=>{
        async function fetchDate() {
            let res = await fetch(`${apiUrl}/Home-services`)
            let data =await res.json()
     
            setServices(data.data)
        }
        fetchDate()
    },[])


    return (
        <section class="tc-services-style1" data-section="2">
                    <div class="container-fluid pt-50">
                        <div class="title mb-80 text-center">
                            <p class="color-666 text-uppercase wow"> our services </p>
                        </div>
                        <div class="services pt-50 ">
                            <div class="row" id="serviceContainer">
                                {
                                    services?.map((service , index)=>{
                                        return (
                                            <ServiceBox key={index} service={service}/>
                                        )

                                    })
                                }
                                 {/* <ServiceBox/>
                                 <ServiceBox/> */}
                               
                            </div>
                        </div>
                        
                    </div>
                </section>
    )
}