import { useEffect, useState } from 'react';
import img1 from '../innerpages/assets/img/1.jpg'
import img2 from '../innerpages/assets/img/2.jpg'

import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination } from 'swiper/modules';
import BlogSlide from '../components/blogSlide';
import apiUrl from '../api/config';
import { Link } from 'react-router-dom';

export default function BlogPage() {
    const [projects , setProjects] = useState([])

    useEffect(()=>{
        async function fetchDataProjects () {
            let res = await fetch (`${apiUrl}/homeProjects`)
            let data = await res.json()
            
            setProjects(data.data)
        }
        fetchDataProjects()
    
    },[])

    const [blog , setBlog] = useState([])

    useEffect(()=>{
        async function fetchData () {
            let res = await fetch (`${apiUrl}/blogs`)
            let data = await res.json()
            
            setBlog(data.data)
        }
       
        fetchData()
    },[])

    return (
        <section class="tc-main-slider-style1">
            <header class="tc-inner-header-style1">
            <div class="container">
                <div class="info col-lg-7">
                    <h1 class="js-title"> Articles</h1>
                   
                </div>
            </div>
        </header>
        <div class="container-fluid">
            <Swiper 
            
            navigation={{
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      }} className='main-slider' modules={[Navigation]}>
        {
            projects?.map((project)=>{
                return(
                <SwiperSlide id={project.id}>
                <div class="slider-card">
                            <img src={project.image} alt="" class="bg"/>
                            <div class="content">
                                <div class="container">
                                    <div class="row">
                                        <div class="col-lg-7">
                                            <div class="info">
                                                <div class="date fsz-12 text-uppercase mb-30">
                                                    <a href="#"> {project.tag} </a>
                                                    <span
                                                        class="circle icon-3 bg-white rounded-circle mx-3"></span>
                                                    <a href="#">{project.date} </a>
                                                </div>
                                                <h2 class="fsz-45 fw-500"> <a  class="hover-orange1">
                                                        {project.title} </a> </h2>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                </SwiperSlide>

                )
            })
        }
              
                <div class="arrows">
                    <div class="swiper-button-prev" id='project-prev'></div>
                    <div class="swiper-button-next" id='project-next'></div>
                </div>
            </Swiper>
        </div>
        <section class="tc-blog-style1" data-section="7">
        <div class="container-fluid position-relative">
            <div class="mb-80 pt-100">
                <div class="row title">
                    <div class="col-lg-9 text-uppercase wow">
                        <h2 class="fsz-45">  Posts </h2>
                    </div>
                   
                </div>
            </div>
          <div className='row'>
                    {
                        blog?.map((blog)=>{
                            return(
                                <div className="col-md-6 col-lg-4">

                                <div key={blog.id} class="blog-card">
                                    <div class="img">
                                        <img src={blog?.image} alt="" class="img-cover"/>
                                    </div>
                                    <div class="info">
                                        <div class="date">
                                            <div class="num fsz-45 mb-2"> {blog.created_at.splice} </div>
                                            <small class="fsz-12 text-uppercase color-666"> {blog.created_at} </small>
                                        </div>
                                        <div class="cont">
                                            <Link to={`/blog-details/${blog.id}`} class="title d-block fsz-20 hover-orange1 mb-15 fw-600"> {blog.title} </Link>
                                            <small class="fsz-12 color-orange1"> {blog.description} </small>
                                        </div>
                                    </div>
                                </div>
                                </div>
                            
                            )
                        })
                    }
               </div>
        </div>
    </section>
    </section>
    )
}