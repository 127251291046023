import ServiceSlider from "../components/serviceSlide";

export default function ServicePage() {
    return (
        <>
            <header class="tc-inner-header-style1">
                <div class="container">
                    <div class="info col-lg-7">
                        <h1 class="js-title"> Services </h1>
                        {/* <!-- <div class="text fsz-18 text-white"> Architecture is a visual art, and the building <br> speak for
                            themselves </div> --> */}
                    </div>
                </div>
            </header>
            <ServiceSlider/>
        </>
    )
}