import { Link } from 'react-router-dom'
import imgCard from '../assets/img/rocks/w-p.png'
import imgCardTitle from '../assets/img/services/ser2.jpg'

export default function ServiceBox({service}) {

    return (
        <div class="col-lg-4 mb-5 mb-lg-0">
        <Link to={`/service/${service.id}`} class="service-card wow fadeInUp" data-wow-delay="0.4s">
            <div class="icon">
                <i class="far fa-bezier-curve"></i>
            </div>
            <h5 class="fsz-24 mb-20 d-none"> Rocks Precision </h5>
            <div class="logo"><img src={service.title} alt=""/></div>
            <div class="text color-666 mb-50">
                {service.description}
            </div>
            <div class="img">
                <img src={service.image} alt="" class="img-cover"/>
            </div>
           
            <Link class="arrow" to={`/service/${service.id}`}> See More </Link>
        </Link>
    </div>
    )
}