import { Link } from "react-router-dom";

export default function Footer () {
    return (
        <footer class="tc-footer-style1">
        <div class="container-fluid">
            <div class="top-content section-padding">
                <div class="row gx-0">
                    <div class="col-lg-4">
                        <div class="info-side">
                            <h2 class="js-title mb-15 text-uppercase">
                                engineered ingenuity
                            </h2>
                            <div class="text fsz-24 color-333 lh-3 fw-600">
                                We believe that architecture has the power to shape lives and uplift communities. Rocks's philosophy is passion for innovation, sustainablity and t imeless aesthetics
                            </div>
                          
                        </div>
                    </div>
                 
                </div>
            </div>
            <div class="foot">
                <div class="row justify-content-between">
                    <div class="col-lg-6">
                        <p class="fsz-13"> © 2024 Rocks. All Right Reserved </p>
                    </div>
                    <div class="col-lg-6">
                        <div class="foot-links mt-4 mt-lg-0">
                            <Link to="/"> HOME </Link>
                            <Link to="/blog"> ARTICLES </Link>
                            <Link to="/services"> Services </Link>
                           
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </footer>
    )
}