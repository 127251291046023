import logo from './logo.svg';

import { Route, Routes } from 'react-router-dom';
import Navbar from './components/navbar';
import "./common/assets/css/lib/line-awesome.css"
import "./common/assets/css/lib/themify-icons.css"
import "./common/assets/css/lib/animate.css"
import "./common/assets/css/lib/jquery.fancybox.css"
import "./common/assets/css/lib/lity.css"
import "./common/assets/css/lib/swiper8.min.css"
import "./common/assets/css/common_style.css"
import "./innerpages/assets/css/innerpages.css"
import "./assets/css/home_1_style.css"
import HomePage from './pages/home';
import { useEffect } from 'react';


import ServicePage from './pages/services';
import Footer from './components/footer';
import BlogPage from './pages/blog';
import { Autoplay } from 'swiper/modules';
import Swiper from 'swiper';
import ServiceDetails from './pages/serviceDetails';
import BlogDetails from './pages/blogDetails';
function App() {
  return (
    <>
  
    <Navbar />
      <Routes>
        <Route path='/' element={<HomePage/>}/>
        <Route path='/services' element={<ServicePage/>}/>
        <Route path='/Blog' element={<BlogPage/>}/>
        <Route path='/service/:serviceId' element={<ServiceDetails/>}/>
        <Route path='/blog-details/:blogId' element={<BlogDetails/>}/>
      </Routes>
      <Footer/>
    </>
  );
}

export default App;
